
import { GenerateXml } from "@/models/entities/generate-xml.interface";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { downloadXmlService } from "@/services/api/generate-xml.service";

const overlayModule = getModule(OverlayModule, store);

@Component
export default class DownloadComplete extends Vue {
  @Prop({ type: Object as () => GenerateXml, default: null, required: true })
  private generateXml: GenerateXml;

  private async downloadXml(): Promise<void> {
    overlayModule.showOverlay();
    await downloadXmlService
      .GetDownloadXml(this.generateXml, false)
      .then((x) => {
        if (x.status == 200) {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/xml;charset=utf-8," + encodeURIComponent(x.data)
          );
          element.setAttribute("download", "module.xml");

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();
          document.body.removeChild(element);
        }
      });
  }
  private close() {
    this.$emit("close");
  }
}
